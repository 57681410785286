import type { HttpClient } from "~/infra/http/HttpClient";

export function getMessages(httpClient: HttpClient, posUuid: string) {
  return httpClient.get(`whatsapp-chat?pos=${posUuid}`);
}

export function sendMessage(httpClient: HttpClient, input: SendMessageInput) {
  return httpClient.post("whatsapp-chat/send", {
    pos: input.pos,
    phone: input.phone,
    message: input.message,
    type: 'text'
  });
}

export function sendImage(httpClient: HttpClient, input: SendImageInput) {
  return httpClient.post("whatsapp-chat/send", {
    pos: input.pos,
    phone: input.phone,
    type: 'image',
    caption: input.caption,
    image: input.image
  });
}

export function sendAudio(httpClient: HttpClient, input: SendAudioInput ) {
  return httpClient.post("whatsapp-chat/send", {
    pos: input.pos,
    phone: input.phone,
    type: 'audio',
    audio : input.audio
  });
}



export function getFullChatById(httpClient: HttpClient, chatId: string) {
  return httpClient.get(`whatsapp-chat/chat/${chatId}`);
}

export function getSalesmanByPos(httpClient: HttpClient, posUuid: string) {
  return httpClient.get(`whatsapp-chat/users/${posUuid}`);
}

export function getChatsBySalesman(httpClient: HttpClient, salesmanId: string, posUuid: string) {
  return httpClient.get(`whatsapp-chat/?pos=${posUuid}&user=${salesmanId}&engaged=true`);
}

type SendMessageInput = {
  pos: string;
  phone: string;
  message: string;
};

type SendImageInput = {
  pos: string;
  phone: string;
  image: string;
  caption?: string;
}

type SendAudioInput = {
  pos: string;
  phone: string;
  audio: any;
}
