import { isAxiosError } from "axios";

const messagesWithoutUser = ref<any[]>([]);

export const useChatsWithoutUser = () => {
  const axios = useAxios({ useV2: true });
  const activeRetailChainStore = useActiveRetailChainAndPosStore();
  const whatsAppStore = useWhatsappChatV1();
  const userStore = useUserStore();

  const isLoading = ref(false);
  const t = useCustomToast();

  const asyncGetChats = async () => {
    if (!activeRetailChainStore.pos) return;
    const uuid = (activeRetailChainStore.pos as { uuid: string }).uuid;

    isLoading.value = true;
    const { data } = await axios.get(
      `/whatsapp-chat?pos=${uuid}&without_user=true`,
    );

    console.assert(data.data.chats, "useChatsWithoutUser - erro");

    messagesWithoutUser.value = data.data.chats;
    console.log(messagesWithoutUser.value);

    isLoading.value = false;
  };

  const triggerUserPickedChat = async (chatId: string) => {
    try {
      const pickedChat = messagesWithoutUser.value.find((m) => m.id === chatId);
      if (!pickedChat.user || !Object.keys(pickedChat).length) {
        pickedChat.user = {
          uuid: "",
        };
      }

      await axios.put(`whatsapp-chat/set-user`, {
        chat: {
          id: chatId,
        },
      });

      pickedChat.user.uuid = userStore.user.uuid;
      whatsAppStore.chats = [...whatsAppStore.chats, pickedChat]
      pickedChat.unread = false
      whatsAppStore.setActiveChat(pickedChat);
      

      messagesWithoutUser.value = [...messagesWithoutUser.value.filter(
        (m) => m.uuid !== pickedChat.uuid
      )]
    } catch (err) {
      if (isAxiosError(err)) {
        return t.axiosError(err);
      }
      console.error(err);
    }
  };

  const receiveNewMessageFromPusher = (message: PusherMessageReceived) => {
    const currentActiveChat = whatsAppStore.getActiveChat;
    const chat = messagesWithoutUser.value.find(
      (m) => m.id === message.chat_id,
    );

    if (chat && Object.keys(currentActiveChat).length > 0) return;
    if (chat && Object.keys(currentActiveChat).length === 0) {
      whatsAppStore.triggerNotification(message.phone, message.message);
      return (chat.messages = [message]);
    }

    whatsAppStore.triggerNotification(message.phone, message.message);
    const createdChat = createNewChat(message)
    messagesWithoutUser.value = [...messagesWithoutUser.value, createdChat]
  };

  const createNewChat = (message: PusherMessageReceived): Chat => {
    return {
      id: message.chat_id,
      messages: [
        {
          created_at: message.created_at,
          message: message.message,
          phone: message.phone,
          type: message.type,
          pos: message.pos,
          received: true,
        },
      ],
      name: message?.phone,
      phone: message.phone,
      pos: {
        uuid: message.pos,
      },
      unread: true,
      user: null,
      uuid: message.uuid,
    };
  };

  return {
    messagesWithoutUser,
    isLoading,
    triggerUserPickedChat,
    asyncGetChats,
    receiveNewMessageFromPusher,
  };
};

export interface PusherMessageReceived {
  message: string;
  created_at: Date;
  type: "text" | "image" | "video";
  received: boolean;
  pos: string;
  phone: string;
  chat_id: string;
  uuid: string;
}

export interface Chat {
  id: string;
  uuid: string;
  phone: string;
  pos: Pos;
  name: string;
  user: null;
  unread: boolean;
  messages: Message[];
}

export interface Message {
  message: string;
  created_at: Date;
  type: string;
  received: boolean;
  pos: string;
  phone: string;
}

export interface Pos {
  id?: number;
  uuid?: string;
  code?: string;
  name?: string;
  city?: string;
  state?: string;
  channel?: string;
}
